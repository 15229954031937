import React from 'react';
import { array, func, object } from 'prop-types';
import { Button, Tabs, Divider, Drawer, Typography } from 'antd';
import { identity, isNil, map } from 'lodash';

import {
    getDisplayColumns,
    getObservationDisplayValues,
} from '../util/schemaHelper';

import MediaLauncher from './MediaLauncher';
import LayerSummary from './LayerSummary';
import { getAggregatedFormatter } from '../util/visualizationsHelper';

const { Text } = Typography;

function ObservationMapSidebar({
    currentFilterSet,
    schema,
    user,
    project,
    selectedStations,
    selectedLayerIdentifyRequests,
    field,
    showInTable,
    onClose,
}) {
    // Mapbox coerces properties to strings. Reconvert to JSON.
    const observations = selectedStations
        ? selectedStations.flatMap(s => JSON.parse(s.properties.observations))
        : [];

    return (
        <Drawer
            className='observation-drawer'
            visible={true}
            placement='right'
            mask={false}
            maskClosable={false}
            closable={true}
            onClose={onClose}
        >
            <Tabs
                defaultActiveKey='1'
                items={[
                    {
                        label: 'Data Summary',
                        key: '1',
                        children: (
                            <>
                                {selectedStations?.length > 0 && (
                                    <>
                                        <Summary
                                            currentFilterSet={currentFilterSet}
                                            schema={schema}
                                            user={user}
                                            project={project}
                                            selectedStations={selectedStations}
                                            observations={observations}
                                            field={field}
                                        />
                                        <br />
                                        <MediaLauncher
                                            observations={observations}
                                        />
                                        {!!observations.length && (
                                            <Button
                                                type='primary'
                                                block
                                                onClick={() =>
                                                    showInTable(
                                                        // Mapbox coerces properties to strings. Reconvert to JSON.
                                                        selectedStations.flatMap(
                                                            s =>
                                                                JSON.parse(
                                                                    s.properties
                                                                        .observations
                                                                )
                                                        )
                                                    )
                                                }
                                            >
                                                Show in table
                                            </Button>
                                        )}
                                    </>
                                )}

                                <Divider style={{ margin: '5px 0' }} />
                                {selectedLayerIdentifyRequests
                                    .filter(
                                        r =>
                                            r.response?.data?.results?.length >
                                            0
                                    )
                                    .map(r => (
                                        <LayerSummary key={r.layer.id} {...r} />
                                    ))}
                            </>
                        ),
                    },
                ]}
            />
        </Drawer>
    );
}

ObservationMapSidebar.propTypes = {
    selectedStations: array,
    selectedLayerIdentifyRequests: array,
    field: object,
    showInTable: func,
    onClose: func,
};

ObservationMapSidebar.defaultProps = {
    selectedStations: null,
    selectedLayerIdentifyRequests: [],
    field: null,
    showInTable: identity,
    onClose: identity,
};

export default ObservationMapSidebar;

const Summary = ({
    currentFilterSet,
    schema,
    user,
    project,
    selectedStations,
    observations,
    field,
}) => {
    const useStations = project?.useStations;

    const stationCount = selectedStations?.length || 0;
    const observationCount = observations?.length || 0;

    const values = field
        ? selectedStations.flatMap(station =>
              !isNil(station?.properties?.[field.name])
                  ? [station.properties[field.name]]
                  : JSON.parse(station?.properties?.observations || '[]')
                        .map(o => o.attributes[field.name])
                        .filter(v => !isNil(v))
          ) || []
        : [];

    const canAggregate = field?.type === 'Number';

    const aggregate = canAggregate
        ? {
              minimum: values.length ? Math.min(...values) : null,
              maximum: values.length ? Math.max(...values) : null,
              average: values.length
                  ? values.reduce((acc, v) => acc + v, 0) / values.length
                  : null,
          }
        : null;

    return (
        <>
            {useStations &&
                (stationCount > 1 ? (
                    <>
                        <Text strong>Station Count:</Text>
                        <br />
                        {stationCount}
                        <br />
                    </>
                ) : (
                    <>
                        <Text strong>Station Name:</Text>
                        <br />
                        {selectedStations[0].properties.stationName}
                        <br />
                    </>
                ))}
            {observationCount > 1 || useStations ? (
                <>
                    <Text strong>Observation Count:</Text>
                    <br />
                    {observationCount}
                    <br />
                    {canAggregate && (
                        <>
                            <Text strong>{field.label} Values:</Text>
                            <br />
                            {values.length}
                            <br />
                            <Text strong>Minimum {field.label}:</Text>
                            <br />
                            {getAggregatedFormatter(
                                field,
                                'minimum',
                                true
                            )(aggregate.minimum)}
                            <br />
                            <Text strong>Maximum {field.label}:</Text>
                            <br />
                            {getAggregatedFormatter(
                                field,
                                'maximum',
                                true
                            )(aggregate.maximum)}
                            <br />
                            <Text strong>Mean (average) {field.label}:</Text>
                            <br />
                            {getAggregatedFormatter(
                                field,
                                'average',
                                true
                            )(aggregate.average)}
                            <br />
                        </>
                    )}
                </>
            ) : observationCount === 1 ? (
                <Observation
                    currentFilterSet={currentFilterSet}
                    schema={schema}
                    user={user}
                    project={project}
                    observation={observations[0]}
                />
            ) : null}
        </>
    );
};

const Observation = ({
    currentFilterSet,
    schema,
    user,
    project,
    observation,
}) => {
    const displayColumns =
        currentFilterSet?.results &&
        getDisplayColumns(
            schema,
            [...currentFilterSet.filters.fields],
            {},
            {},
            currentFilterSet.filters.fieldOrder
        );
    const data = getObservationDisplayValues(
        schema,
        currentFilterSet.filters.displayFields ||
            currentFilterSet.filters.fields,
        currentFilterSet.results.filter(
            ({ stationId }) => stationId === observation.stationId
        ),
        user,
        project
    )[0];
    const detailRows = displayColumns
        ? map(displayColumns, ({ dataIndex, title }) => {
              if (!dataIndex || !title) {
                  return;
              }

              // Don't list empty fields
              const value = data[dataIndex];

              if (!value) {
                  return;
              }

              return (
                  <li
                      className='observation-drawer__detail--item'
                      key={dataIndex}
                  >
                      <Text className='label'>{title}</Text>
                      <Text className='value'>{value}</Text>
                  </li>
              );
          })
        : [];

    return (
        <div>
            <h3 className='observation-drawer__title'>Observation</h3>
            <ul className='observation-drawer__detail'>{detailRows}</ul>
        </div>
    );
};
