import { orange } from '@ant-design/colors';

/* URLs */
export const apiRootUrl = '/api/v3';
export const authLoginUrl = `${apiRootUrl}/auth/login`;
export const authLogoutUrl = `${apiRootUrl}/auth/logout`;
export const userUrl = `${apiRootUrl}/user`;
export const authPasswordResetUrl = `${apiRootUrl}/auth/resetpass`;
export const makeAuthPasswordChangeUrl = hash =>
    `${apiRootUrl}/auth/changepass/${hash}/`;
export const observationUrl = `${apiRootUrl}/observation/`;
export const manageUrl = `${apiRootUrl}/manage/`;
export const manageUsersGroupsUrl = `${manageUrl}users-groups/`;
export const stationUrl = `${apiRootUrl}/station/`;
export const photoUrl = `${apiRootUrl}/photo/`;
export const mediaUrl = `${apiRootUrl}/media/`;
export const getSignedS3MediaUrl = `${mediaUrl}sign-file/`;
export const visualizationsUrl = `${apiRootUrl}/visualizations/`;
export const managerUrl = `${apiRootUrl}/project-managers/`;
export const roleUrl = `${apiRootUrl}/project-role/`;
export const locUrl = `${apiRootUrl}/local-coordinators/`;
export const userSearchUrl = `${userUrl}/query?f=json`;
export const requestProjectAccessUrl = `${userUrl}/project/access/`;

export const makeProjectUrl = name => `${apiRootUrl}/project/${name}`;
export const makeSchemaUrl = (name, rootUrl) =>
    `${rootUrl || apiRootUrl}/schema/${name}`;
export const makeQueryUrl = (name, rootUrl) =>
    `${makeSchemaUrl(name, rootUrl)}/query?f=json`;
export const makeCsvUrl = (schemaName, query, rootUrl) =>
    `${makeSchemaUrl(schemaName, rootUrl)}/csv?query=${query}`;
export const makeQueryManagerFieldsUrl = (name, rootUrl) =>
    `${makeSchemaUrl(name, rootUrl)}/query-manager-fields?f=json`;
export const makeStationUrl = stationId => `${stationUrl}${stationId}/`;
export const makeStationsUrl = schemaName =>
    `${apiRootUrl}/station/?schema=${schemaName}&wkid=4326`;
export const makeObservationUrl = observationId =>
    `${apiRootUrl}/observation/${observationId}/`;
export const makeOrganizationsUrl = name =>
    `${makeProjectUrl(name)}/organization/`;
export const makeCommentUrl = url => `${url}comment/`;
export const makePhotoUrl = photoId => `${photoUrl}${photoId}`;
export const makeMediaUrl = mediaId => `${mediaUrl}${mediaId}`;
export const makeVisualizationUrl = visualizationId =>
    `${visualizationsUrl}${visualizationId}/`;
export const makeUserApprovalUrl = userId =>
    `${apiRootUrl}/user-approval/${userId}/`;
export const makeProjectUsersUrl = name => `${makeProjectUrl(name)}/users/`;
export const makeLocUrl = locId => `${locUrl}${locId}`;

export const makeSuggestUrl = (text, dataExtent) =>
    `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${text}&searchExtent=${dataExtent.join()}&f=json`;
export const makeGeocodeUrl = (address, magicKey, dataExtent) =>
    `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?SingleLine=${address}&magicKey=${magicKey}&searchExtent=${dataExtent.join()}&f=json`;

export const getIdbObservationsUrl = '/idb/observations/';
export const getUpdateIdbObservationsStationIdUrl = `${getIdbObservationsUrl}update-station-id/`;
export const getIdbStationsUrl = '/idb/stations/';

/* Strings */
export const models = Object.freeze({
    OBSERVATION: 'OBSERVATION',
    STATION: 'STATION',
});

// Warning: This naming pattern constant must agree with that on the server
const parentSchemaNamePattern = '_parent';
export const makeParentSchemaName = projectName =>
    `${projectName}${parentSchemaNamePattern}`.toLowerCase();
export const makeParentSchemaModelName = (projectName, modelType) =>
    `${makeParentSchemaName(projectName)}_${modelType}`.toLowerCase();

// values match type options in API Media model
export const mediaTypes = Object.freeze({
    photo: 'photo',
    video: 'video',
    audio: 'audio',
    document: 'document',
});
export const mediaCategoryNames = Object.freeze({
    [mediaTypes.photo]: 'Photos',
    [mediaTypes.video]: 'Videos',
    [mediaTypes.audio]: 'Audio',
    [mediaTypes.document]: 'Documents',
});
export const mediaPropertyNames = {
    [mediaTypes.photo]: 'photos',
    [mediaTypes.video]: 'videos',
    [mediaTypes.audio]: 'audio',
    [mediaTypes.document]: 'documents',
};

export const acceptedImageFormats = '.png,.jpg,.jpeg';
export const acceptedDocumentFormats =
    '.pdf,.txt,.csv,.xls,.xlsx,.pages,.keynote,.key,.numbers,.ppt,.pptx';
export const acceptedAudioFormats = '.wav,.mp3';
export const acceptedVideoFormats = '.mp4,.mov';

export const staleValue = Object.freeze({
    redraw: 'redraw',
    fetch: 'fetch',
});

export const hardcodedMyOfflineFilterSetName = 'Offline';
export const hardcodedMyFilterSetName = 'My';
export const hardcodedRecentFilterSetName = 'Recent';
export const hardcodedAllFilterSetName = 'All';
export const hardcodedGroupFilterSetName = 'Group';

// data status
export const underReviewStatus = 'Under Review';
export const draftStatus = 'Draft';

/* Colors */
export const colors = Object.freeze({
    warning: orange[5],
});

/* Preferences */
export const defaultPageSize = 15;
export const dateFormat = 'YYYY-MM-DD'; // antd DatePicker input format
export const timeFormat = 'h:mm a'; // antd TimePicker 12H input format
export const dateTimeFormat = `${dateFormat} ${timeFormat}`; // antd DatePicker with time input format
export const commentDateTimeFormat = 'MMMM D, YYYY h:mm a';

// center of the contiguous USA
export const centerLat = 39.833333;
export const centerLong = -98.583333;
export const initialMapZoom = 3;

// Closest zoom level that works across major basemaps
export const closestZoom = 11;

/* Form schema */
export const passwordResetFormSchema = [
    {
        description: null,
        name: 'user',
        required: true,
        label: 'Email',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
];

export const loginFormSchema = [
    {
        description: null,
        name: 'id',
        required: true,
        label: 'Email',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
    {
        description: null,
        name: 'password',
        required: true,
        label: 'Password',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
];

export const baseRegistrationFormSchema = [
    {
        description: null,
        name: 'userName',
        required: true,
        label: 'Username',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
    {
        description: null,
        name: 'email',
        required: true,
        label: 'Email',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
    {
        description: null,
        name: 'password',
        required: true,
        label: 'Password',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
    {
        description: null,
        name: 'firstName',
        required: true,
        label: 'First name',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
    {
        description: null,
        name: 'lastName',
        required: true,
        label: 'Last name',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
    {
        description: null,
        name: 'organization',
        required: true,
        label: 'School/Organization',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
];

const locationFieldAddendum = [
    {
        description: null,
        name: 'latitude',
        required: true,
        label: 'Latitude',
        values: null,
        units: null,
        type: 'Number',
        isDefault: true,
    },
    {
        description: null,
        name: 'longitude',
        required: true,
        label: 'Longitude',
        values: null,
        units: null,
        type: 'Number',
        isDefault: true,
    },
];

const stationIdFieldAddendum = [
    {
        description: null,
        name: 'stationId',
        required: true,
        label: 'Station ID',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
];

export const observationSchemaFolderAddendum = [
    {
        label: 'Observation details',
        repeatable: false,
        fields: [
            {
                description: null,
                name: 'collectionDate',
                required: true,
                label: 'Observation date',
                values: null,
                units: null,
                type: 'Date',
                isDefault: true,
            },
        ],
    },
];

export const observationSchemaFolderAddendumForUseStationsUpload = [
    {
        label: 'Upload Fields',
        repeatable: false,
        fields: [...stationIdFieldAddendum],
    },
].concat(observationSchemaFolderAddendum);

export const observationSchemaFolderAddendumForUpload = [
    {
        label: 'Upload Fields',
        repeatable: false,
        fields: [...locationFieldAddendum],
    },
].concat(observationSchemaFolderAddendum);

export const stationFieldAddendum = [
    {
        description: null,
        name: 'stationName',
        required: true,
        label: 'Station name',
        values: null,
        units: null,
        type: 'String',
        isDefault: true,
    },
];

export const stationFieldAddendumForUpload = [
    ...stationFieldAddendum,
    ...stationIdFieldAddendum,
    ...locationFieldAddendum,
];

export const stationIdKey = 'stationId';

export const antdUploadStatus = {
    uploading: 'uploading',
    done: 'done',
    error: 'error',
    removed: 'removed',
};

export const dataLayerClass = {
    dynamic: 'fieldscope.model.layer.DynamicMapServiceLayerModel',
    tiled: 'fieldscope.model.layer.TiledMapServiceLayerModel',
};

export const maxMediaFileSizeInBytes = 100 * 1024 * 1024;

export const isDevOrStaging = (function() {
    if (window?.location?.host) {
        const host = window.location.host.toLowerCase();
        return host.includes('.vbox') || host.includes('.staging');
    }
    return false;
})();

// Divergent color scheme from Color Brewer 2
// https://colorbrewer2.org/?type=qualitative&scheme=Paired&n=12
export const divergentColors = [
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
];

// mpn65 is a qualitative palette of 65 colors
// https://google.github.io/palette.js/
// https://github.com/google/palette.js/blob/79a703df344e3b24380ce1a211a2df7f2d90ca22/palette.js#L802-L823
// The first color in the official scheme is moved to the end here,
// because the red #ff0029 is too intense.
export const mpn65Colors = [
    '#377eb8',
    '#66a61e',
    '#984ea3',
    '#00d2d5',
    '#ff7f00',
    '#af8d00',
    '#7f80cd',
    '#b3e900',
    '#c42e60',
    '#a65628',
    '#f781bf',
    '#8dd3c7',
    '#bebada',
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#fccde5',
    '#bc80bd',
    '#ffed6f',
    '#c4eaff',
    '#cf8c00',
    '#1b9e77',
    '#d95f02',
    '#e7298a',
    '#e6ab02',
    '#a6761d',
    '#0097ff',
    '#00d067',
    '#000000',
    '#252525',
    '#525252',
    '#737373',
    '#969696',
    '#bdbdbd',
    '#f43600',
    '#4ba93b',
    '#5779bb',
    '#927acc',
    '#97ee3f',
    '#bf3947',
    '#9f5b00',
    '#f48758',
    '#8caed6',
    '#f2b94f',
    '#eff26e',
    '#e43872',
    '#d9b100',
    '#9d7a00',
    '#698cff',
    '#d9d9d9',
    '#00d27e',
    '#d06800',
    '#009f82',
    '#c49200',
    '#cbe8ff',
    '#fecddf',
    '#c27eb6',
    '#8cd2ce',
    '#c4b8d9',
    '#f883b0',
    '#a49100',
    '#f48800',
    '#27d0df',
    '#a04a9b',
    '#ff0029',
];

// Milliseconds to wait for the user to stop typing before triggering a response
export const waitForTyping = 500;

export const unknownValue = '(Unknown)';
export const nothingCategoricalValues = '(Nothing)';
export const multipleCategoricalValues = '(Multiple)';
export const otherCategoricalValues = '(Other)';

export const filterSetDefaultResultLimit = 5000;

export const makeEsriFeatureServiceUrl = baseUrl =>
    `${baseUrl}/query?returnGeometry=true&where=1%3D1&f=json&outSR=4326`;

export const managerFieldDataLabelMap = Object.freeze({
    observationEntryDate: 'Entry Date',
    isObservationTrusted: 'Trusted',
    isStationPublic: 'Public Station',
    email: 'Email',
});

// Screen widths
export const mobileWidthQuery = '(min-width: 1000px)';

/* Data and CSV */
export const csvPageSize = 100;
// Threshold is an arbitrary value for ui building and should
// be evaluated with testing the 100k work
export const largeDataThreshold = 100;

export const downloadingExitWarning =
    "You're currently preparing one or more files for download. Navigating away will cancel progress. Stay on the page to wait for the download to finish before navigating away.";

// Maps from an integer to side of a UPlot for axis labels
// UPlot axis documentation: https://github.com/leeoniya/uPlot/blob/8fb9510d16fe63dfd5952bbf63b03944f6968fdd/dist/uPlot.d.ts#L1005
export const uPlotSide = Object.freeze({
    TOP: 0,
    RIGHT: 1,
    BOTTOM: 2,
    LEFT: 3,
});

export const graphLegendPaddingTop = '40px';

// Any changes to these folder vairables must also be made to MANAGER_ONLY_KEYS in src/fieldscope/core/api3/resources.py
export const observationManagerFieldsSchemaFolder = {
    fields: [
        {
            label: 'Entry Date',
            name: 'observationEntryDate',
            type: 'Date',
        },
        {
            label: 'Trusted',
            name: 'isObservationTrusted',
            type: 'Boolean',
        },
        {
            label: 'Email',
            name: 'email',
            type: 'String',
        },
    ],
    label: 'Manager Fields For Observation',
    repeatable: false,
};
export const stationManagerFieldsSchemaFolder = {
    fields: [
        {
            label: 'Public Station',
            name: 'isStationPublic',
            type: 'Boolean',
        },
    ],
    label: 'Manager Fields For Station',
    repeatable: false,
};
export const managerFieldSchemaFolders = [
    observationManagerFieldsSchemaFolder,
    stationManagerFieldsSchemaFolder,
];

// Using 31 allows for showing individual bars for each day in a month with 31 days.
export const maxHistogramBarCount = 31;

// Numeric schema fields can specify a precision. All schemas include latitude
// and longitude by default rather than being configured in the database so we
// must provide a precision in code.
export const latLngPrecision = 6;
