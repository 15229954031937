import React from 'react';
import { Collapse, Divider } from 'antd';
import { useWindowSize } from 'react-use';

import MapLegendItem from './MapLegendItem';

const { Panel } = Collapse;

const MapLegend = ({ activeDataLayers, legendValues, stationLegendTitle }) => {
    const { height } = useWindowSize();
    const maxHeight = Math.max(height - 320, 280);

    return (
        <>
            Legend
            <Divider style={{ margin: '4px' }} />
            <div
                style={{
                    maxHeight: `${maxHeight}px`,
                    overflow: 'auto',
                }}
            >
                <Collapse bordered={false} defaultActiveKey='Station'>
                    <Panel header={stationLegendTitle} key='Station'>
                        {legendValues.map((v, i) => (
                            <div key={i}>
                                <svg
                                    height={22}
                                    width={20}
                                    style={{ verticalAlign: 'middle ' }}
                                >
                                    <circle
                                        cx={10}
                                        cy={10}
                                        r={v.size}
                                        fill={v.color}
                                        stroke={v.strokeColor}
                                        strokeWidth='1'
                                    />
                                </svg>
                                {v.label}
                            </div>
                        ))}
                    </Panel>
                </Collapse>
                {activeDataLayers.map(layer => (
                    <MapLegendItem key={layer.label} layer={layer} />
                ))}
                <em>Size on map is double</em>
            </div>
        </>
    );
};

export default MapLegend;
