import proj4 from 'proj4';
import { WebMercatorViewport } from 'react-map-gl';
import { clamp, find, flatMap, isNaN, isNil, isNumber, uniq } from 'lodash';
import interpolate from 'color-interpolate';
import Color from 'color';

import {
    centerLat,
    centerLong,
    initialMapZoom,
    dataLayerClass,
    multipleCategoricalValues,
    otherCategoricalValues,
} from '../util/constants';
import { formatUnit } from '../util/units';
import { separateObservationMedia } from '../util/mediaHelper';
import { isNilOrNan } from './visualizationsHelper';
import querystring from 'querystring';

export const BASEMAP_NATGEO_WORLD = {
    iconUrl: '',
    zoomLevels: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16',
    description:
        'This map is designed to be used as a general reference map. It was developed by National Geographic and Esri and reflects the distinctive National Geographic cartographic style in a multi-scale reference map of the world.',
    url:
        'http://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer',
    class: 'fieldscope.model.basemap.ArcGISBasemapLayerModel',
    label: 'National Geographic',
};

export const DEFAULT_BASEMAP_ZOOM_LEVELS =
    '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16';

export function makeTileUrlFromFieldScopeBasemapDefinition(definition) {
    // The application only runs over https and will fail to load non-secure
    // content so we force the protocol for tile URLs to be https regardless of the definition.
    const url = new URL(definition.url);
    if (
        definition.class === 'fieldscope.model.basemap.ArcGISBasemapLayerModel'
    ) {
        return `https://${url.host}${url.pathname}/tile/{z}/{y}/{x}`;
    }
    return `https://${url.host}${url.pathname}`;
}

export function makeAGSTileUrlFromDataSourceDefinition(definition) {
    // The application only runs over https and will fail to load non-secure
    // content so we force the protocol for tile URLs to be https regardless of the definition.
    const url = new URL(definition.url);
    if (definition.class === dataLayerClass.tiled) {
        return `https://${url.host}${url.pathname}/tile/{z}/{y}/{x}`;
    }
    return `https://${url.host}${url.pathname}`;
}

export function makeLayerAttributionFromFieldScopeBasemapDefinition(
    definition
) {
    if (
        definition.class === 'fieldscope.model.basemap.ArcGISBasemapLayerModel'
    ) {
        return `Tiles © <a href="${definition.url}">ArcGIS</a>`;
    }
    const url = new URL(definition.url);
    return `Tiles © <a href="${url.protocol}//${url.host}">${url.host}</a>`;
}

/*
  Build a MapboxGL layer and source from a basemap definition returned from the
  project API. `BASEMAP_NATGEO_WORLD` is an example of one of these layer definitions
 */
export function makeBasemapLayerAndSourceFromFieldScopeDefinition(definition) {
    const zoomLevels = (definition.zoomLevels || DEFAULT_BASEMAP_ZOOM_LEVELS)
        .split(',')
        .map(zoomLevelString => parseInt(zoomLevelString, 10));
    return [
        {
            id: definition.label,
            type: 'raster',
            source: definition.label,
            minzoom: zoomLevels[0],
            maxzoom: zoomLevels[zoomLevels.length - 1],
        },
        {
            type: 'raster',
            tiles: [makeTileUrlFromFieldScopeBasemapDefinition(definition)],
            tileSize: 256,
            attribution: makeLayerAttributionFromFieldScopeBasemapDefinition(
                definition
            ),
        },
    ];
}

/*
  Given and object defining an AGS dynamic map service layer, create a URL
  template that will allow MapboxGL to fetch tiles from the map service using
  the `export` endpoint.

  Query string arguments present in `definition.url` will also be present in the
  generated URL.

  If `definition.url` ends with a layer id (for example,
  http://example.com/ags/MapServer/1) then the layer id will be stripped from
  id will be stripped from the path and converted to a "layers=show:1" query
  string argument.
 */
export function makeAGSDynamicTileUrlFromDataSourceDefinition(definition) {
    const url = new URL(definition.url);
    const exportOptions = {
        bboxSR: '3857',
        bbox: '{bbox-epsg-3857}',
        transparent: 'true',
        dpi: '96',
        size: '256,256',
        f: 'image',
    };
    // `substring` is used to strip off the leading ?
    const layerOptions = querystring.parse(
        url.search ? url.search.substring(1) : ''
    );

    // The `+ 1` skips the / character and just returns the path segment
    const lastPathSegment = url.pathname.substring(
        url.pathname.lastIndexOf('/') + 1
    );
    const pathLayerId = parseInt(lastPathSegment);
    const pathOptions = !isNaN(pathLayerId)
        ? { layers: `show:${pathLayerId}` }
        : {};

    // We use `decodeURIComponent` to reverse the encoding the URI components
    // because we are building at template URL that we need to contain literal
    // `{}` characters.
    const query = decodeURIComponent(
        querystring.stringify({
            ...exportOptions,
            ...pathOptions,
            ...layerOptions,
        })
    );

    const mapServerPathIndex =
        url.pathname.toLowerCase().lastIndexOf('mapserver') + 9;

    return `https://${url.host}${url.pathname.substring(
        0,
        mapServerPathIndex
    )}/export?${query}`;
}

export function makeLayerAttributionFromDataSourceDefinition(definition) {
    // TODO: Create attribution string
    return '';
}

/*
  Build a MapboxGL layer and source from a data layer definition returned from the
  project API.
*/
export function makeLayerAndSourceFromDataSourceDefinition(definition) {
    const layerClass = definition['class'];
    if (
        layerClass !== dataLayerClass.tiled &&
        layerClass !== dataLayerClass.dynamic
    ) {
        throw Error(`Unknown layer class ${layerClass}`);
    }

    const layer = {
        id: definition.label,
        type: 'raster',
        source: definition.label,
        paint: definition.paint || {},
    };

    const tileUrl =
        layerClass === dataLayerClass.tiled
            ? makeAGSTileUrlFromDataSourceDefinition(definition)
            : makeAGSDynamicTileUrlFromDataSourceDefinition(definition);

    const source = {
        type: 'raster',
        tiles: [tileUrl],
        tileSize: 256,
        attribution: makeLayerAttributionFromDataSourceDefinition(definition),
    };

    return [layer, source];
}

export function makeMapStyle({ basemap, sources, layers } = {}) {
    const [
        baseLayer,
        baseSource,
    ] = makeBasemapLayerAndSourceFromFieldScopeDefinition(
        basemap || BASEMAP_NATGEO_WORLD
    );

    return {
        version: 8,
        sources: Object.assign(
            {
                [baseLayer.source]: baseSource,
            },
            sources
        ),
        layers: layers ? [baseLayer].concat(layers) : [baseLayer],
        glyphs: '/fonts/{fontstack}/{range}.pbf',
    };
}

export const makeStationMapStyle = (
    basemap,
    stationData,
    activeDataLayers,
    layerOpacity,
    symbology
) => {
    const layersAndSources =
        activeDataLayers?.map(definition => {
            if (layerOpacity && layerOpacity[definition.id]) {
                definition.paint = Object.assign({}, definition.paint, {
                    'raster-opacity': layerOpacity[definition.id] / 100.0,
                });
            }
            return makeLayerAndSourceFromDataSourceDefinition(definition);
        }) || [];
    const data = layersAndSources.reduce(
        (acc, [layer, source]) => {
            acc.sources[layer.source] = source;
            acc.layers.push(layer);
            return acc;
        },
        { sources: {}, layers: [] }
    );

    return {
        basemap,
        sources: {
            'stations-source': {
                type: 'geojson',
                data: stationData,
            },
            ...data.sources,
        },
        layers: [...data.layers, makeStationsLayer('stations-source')],
    };
};

export const makeStationsLayer = sourceId => {
    return {
        id: 'stations',
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': ['get', 'circleColor'],
            'circle-radius': ['get', 'markerSize'],
            'circle-stroke-color': ['get', 'circleStrokeColor'],
            'circle-stroke-width': ['get', 'circleStrokeWidth'],
        },
    };
};

export const makeStationFeatureCollection = (filterSetData, options) => {
    return {
        type: 'FeatureCollection',
        features: filterSetData
            ? makeStationFeatures(filterSetData, options)
            : [],
    };
};

const stationValue = (station, options) => {
    const symbology = options?.symbology || null;
    const fieldName = symbology?.field?.name || options?.field;
    if (!symbology?.aggregateBy && !fieldName) {
        return station.observations.length;
    }
    // We filter by isNil so that zeroes, which are falsey, are not filtered out
    const values = !isNil(station.attributes[fieldName])
        ? [station.attributes[fieldName]]
        : station.observations
              .map(o => o.attributes[fieldName])
              .filter(v => !isNil(v));

    if (values.length === 0) {
        return undefined;
    }

    if (symbology?.['class']?.indexOf('CategoricalColorSymbology') >= 0) {
        if (uniq(values).length > 1) {
            return multipleCategoricalValues;
        }
        return values[0];
    }

    // These are the aggregates supported by the legacy client
    // https://github.com/NGFieldScope/FieldScope-Client5/blob/a1a8ba9d3dd99d7bf49646880971422797a5d062/src/main/fieldscope/model/obs/AggregationType.as#L8-L12
    const aggregates = {
        Count: () => station.observations.length,
        Sum: () => values.reduce((a, b) => a + b, 0),
        Avg: () => values.reduce((a, b) => a + b, 0) / values.length,
        Min: () => Math.min(...values),
        Max: () => Math.max(...values),
    };
    return aggregates[symbology.aggregateBy]();
};

const stationsValueRange = (stations, options) => {
    if (!stations) {
        return { min: undefined, max: undefined };
    }
    const symbology = options?.symbology;
    const fieldName = symbology?.field?.name || options?.field;
    const values = !fieldName
        ? // if no fieldName, use observation counts
          stations.map(s => s.observations.length)
        : stations.flatMap(s =>
              // We filter by isNil so that zeroes, which are falsey, are not filtered out
              s.observations
                  .map(o => o.attributes[fieldName])
                  .filter(v => !isNil(v))
          );

    if (values.length === 0) {
        return { min: null, max: null };
    }

    return { min: Math.min(...values), max: Math.max(...values) };
};

// From: https://www.trysmudford.com/blog/linear-interpolation-functions/
const lerp = (x, y, a) => x * (1 - a) + y * a;
const invlerp = (x, y, a) => clamp((a - x) / (y - x));
export const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));

export const hexString = c =>
    isNil(c)
        ? ''
        : isNumber(c)
        ? `#${
              c.toString(16).length < 6
                  ? // prefix with 0s if the hex output isn't len 6
                    '0'.repeat(6 - c.toString(16).length) + c.toString(16)
                  : c.toString(16)
          }`
        : `#${c}`;

export const makeSymbolizer = (stations, options) => {
    const symbology = options?.symbology;
    const fieldDefinitions = options?.fieldDefinitions;

    const field = find(fieldDefinitions, { name: symbology?.field?.name });

    const colormap =
        symbology?.colors && symbology.colors.length
            ? interpolate(symbology.colors.map(hexString))
            : null;

    const normalizeColorCategories = (acc, [key, value]) => {
        // Categories are defined in the symbology using the label, not the value.
        const choice = find(field?.values, { label: key });
        // We add both the value and label as keys in the color lookup
        // table so that we can use the same dictionary for both the
        // legend and the map symbols
        acc[choice?.value || key] = hexString(value);
        acc[key] = hexString(value);
        return acc;
    };

    const colorCategories = !symbology?.categories
        ? null
        : Array.isArray(symbology.categories)
        ? symbology.categories.reduce(
              (acc, { name, color }) =>
                  normalizeColorCategories(acc, [name, color]),
              {}
          )
        : Object.entries(symbology.categories).reduce(
              normalizeColorCategories,
              {}
          );

    const markerMin = symbology?.minimumSize ? symbology.minimumSize / 2 : 6;

    const markerMax = symbology?.maximumSize ? symbology.maximumSize / 2 : 16;

    const { min, max } = stationsValueRange(stations, options);

    const markerSize = value => {
        if (isNilOrNan(value)) {
            return 0;
        }
        // If we are symbolizing using categorical colors, we want to hide the
        // marker (by setting the size to zero) if the value is not a defined
        // color, or the special `multipleCategoricalValues` value, and the
        // symbology does not define an `otherColor`.
        if (
            colorCategories &&
            value !== multipleCategoricalValues &&
            value !== otherCategoricalValues &&
            !colorCategories[value] &&
            !symbology?.otherColor
        ) {
            return 0;
        }
        // We divide symbology-defined sizes by 2 because the sizes in
        // the legacy schema created very large markers. We suspect it has to do
        // with radius vs. diameter.
        if (symbology?.size) {
            return symbology.size / 2;
        }
        if (isNil(min) || isNil(max) || !value || min === max) {
            return markerMin;
        }
        return range(min, max, markerMin, markerMax, value);
    };

    const markerColor = (station, value) => {
        if (isNil(value)) {
            return '#ffffff';
        }
        if (colorCategories) {
            return (
                (value === multipleCategoricalValues &&
                    hexString(symbology?.groupColor)) ||
                colorCategories[value] ||
                hexString(symbology?.otherColor) ||
                '#000'
            );
        }
        if (colormap) {
            if (min === max) {
                return colormap(0);
            }
            return colormap(invlerp(min, max, value));
        }
        if (symbology?.color) {
            return hexString(symbology.color);
        }
        return station?.selected ? '#ff0000' : '#ff7d00';
    };

    const markerStrokeColor = (station, value) => {
        const colorString = markerColor(station, value);
        try {
            return Color(colorString)
                .darken(0.3)
                .string();
        } catch {
            // If there is an invalid color that causes a failure when trying to
            // process it into a derivative color, just return the color
            return colorString;
        }
    };

    const specialColors = symbology?.otherColor
        ? [multipleCategoricalValues, otherCategoricalValues]
        : [multipleCategoricalValues];

    const midpoint = (min + max) / 2;
    const interval = (midpoint - min) / 2;
    const roundValues = values =>
        values.map(v => v && v.toFixed(field?.precision || 0));
    const representativeValues =
        colorCategories && !Array.isArray(symbology.categories)
            ? Object.keys(symbology.categories).concat(specialColors)
            : colorCategories && Array.isArray(symbology.categories)
            ? symbology.categories.map(cat => cat.name).concat(specialColors)
            : max - min >= 5
            ? roundValues([
                  min,
                  min + interval,
                  midpoint,
                  midpoint + interval,
                  max,
              ])
            : roundValues([min, midpoint, max]);
    // If there is no symbology or the symbology does not define a field, then
    // we will be rendering the same marker for all data. We create a single item
    // array with hard coded values so that we can use the same rendering logic.
    // We are purposefully filtering out zeros from the representativeValues
    const legendValues =
        field || !symbology
            ? [...new Set(representativeValues.filter(v => !!v))].map(v => {
                  return {
                      label: v,
                      // the markers in the legend are smaller than on the map
                      size: Math.floor(markerSize(v) / 2),
                      color: markerColor(null, v),
                      strokeColor: markerStrokeColor(null, v),
                  };
              })
            : [
                  {
                      label: '',
                      size: 3,
                      color: markerColor(null, 0),
                      strokeColor: markerStrokeColor(null, 0),
                  },
              ];

    const defaultAggregateLabel = 'Observation Count';
    const aggregateLabels = {
        Count: defaultAggregateLabel,
        Sum: 'Sum',
        Avg: 'Mean (average)',
        Min: 'Minimum',
        Max: 'Maximum',
    };

    const aggregate = symbology?.categories
        ? ''
        : aggregateLabels[symbology?.aggregateBy] || defaultAggregateLabel;
    const label =
        (symbology?.categories || aggregate !== defaultAggregateLabel) &&
        field?.label
            ? field.label
            : '';
    const units =
        aggregate !== defaultAggregateLabel && field?.units
            ? `(${formatUnit(field.units)})`
            : '';

    const stationLegendTitle = `${aggregate} ${label} ${units}`.trim();

    return {
        markerSize,
        markerColor,
        markerStrokeColor,
        legendValues,
        stationLegendTitle,
    };
};

const makeStationFeatures = (stations, options) => {
    const symbolizer = options?.symbolizer || makeSymbolizer(stations, options);
    const { markerSize, markerColor, markerStrokeColor } = symbolizer;

    return stations
        ? stations.map(station => {
              const { x, y } = station.geometry;
              const value = stationValue(station, options);

              return {
                  type: 'Feature',
                  properties: {
                      observationCount: station.observations.length,
                      observations: station.observations.map(o =>
                          Object.assign(o, separateObservationMedia(o))
                      ),
                      markerSize: markerSize(value, options),
                      stationName: station.stationName,
                      circleColor: markerColor(station, value),
                      circleStrokeColor: markerStrokeColor(station, value),
                      circleStrokeWidth: station.selected ? 4 : value ? 1 : 0,
                  },
                  geometry: {
                      type: 'Point',
                      coordinates: [x, y],
                  },
              };
          })
        : [];
};

export const makeSelectedStationViewport = stations => {
    const selectedStations = stations?.filter(s => s.selected);
    if (selectedStations?.length > 0) {
        // Calculate bounds as SW and NE corner of all selected stations
        const xs = selectedStations.map(s => s.geometry.x);
        const ys = selectedStations.map(s => s.geometry.y);
        const bounds = [
            [Math.min(...xs), Math.min(...ys)],
            [Math.max(...xs), Math.max(...ys)],
        ];

        // Need to specify width and height for this to work
        // See https://github.com/uber-archive/viewport-mercator-project/issues/103#issuecomment-542949766
        const wmv = new WebMercatorViewport({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        return wmv.fitBounds(bounds);
    }
};

export const defaultExtentToViewport = ({
    center: { x = centerLong },
    center: { y = centerLat },
    scale,
}) => {
    const [longitude, latitude] = transformWmToGeographic(x, y);
    const zoom = scale ? tranformScaleToZoom(scale) : initialMapZoom;
    return {
        longitude,
        latitude,
        zoom,
    };
};

// Transform a Web Mercator coordinate to a geographic coordinate.
const transformWmToGeographic = (x, y) => {
    return proj4('EPSG:3857', 'EPSG:4326', [x, y]);
};

const tranformScaleToZoom = scale => {
    /* Convert scale to zoom level via forumla 591657550.500000 / 2^(level-1)
         https://gis.stackexchange.com/a/81390

        Note that fieldscope map scale settings for a project are selected from
        a list of ESRI defined scales that a resolution dependent on early raster
        tile dpi structure and settings as defined by Google Maps. These don't translate
        directly to "zoom levels", so this is an approximation.

        The forumla was adapted to account for a posting found here:
        https://www.esri.com/arcgis-blog/products/product/mapping/web-map-zoom-levels-updated/
    */
    return Math.round(Math.log(591657550.5 / (scale / 2)) / Math.log(2)) - 2;
};

/*
  Parse the `mapStyle` and derive a global minimum and maximum zoom based on the
  allowable zoom levels of the individual layers.
*/
export const getZoomRange = mapStyle =>
    mapStyle.layers.reduce(
        (acc, layer) => {
            // We add and subtract 1 to the min and max respectively because
            // MapboxGL starts hiding the layer at the min and max values set in
            // the `mapStyle`
            if (layer.minzoom) {
                acc.minzoom = Math.max(acc.minzoom, layer.minzoom + 1);
            }
            if (layer.maxzoom) {
                acc.maxzoom = Math.min(acc.maxzoom, layer.maxzoom - 1);
            }
            return acc;
        },
        { minzoom: 0, maxzoom: 22 }
    );

/*
  Derive the global min and max zoom from a map style and return a function that
  clamps a specified zoom to that range.
*/
export const makeZoomClamp = mapStyle => {
    const { minzoom, maxzoom } = getZoomRange(mapStyle);
    return zoom => clamp(zoom, minzoom, maxzoom);
};

/*
  Given a data layer configuration, a clicked point location, and the details of
  the current map view create a properly formed AGS REST `identify` URL.

  Parameters:
  layer - An object taken from the project API response representing a data layer
  lngLat - The point at which we are querying the layer
  bounds - The current 4326 bounding box of the map in [[xmin, ymin], [xmax, ymax]] format
  width - The width in pixels of the map
  height - The width in pixels of the map

  References:
  https://developers.arcgis.com/rest/services-reference/identify-map-service-.htm
  https://github.com/Esri/esri-leaflet/blob/542022a2f216b7ad43471876a693a0257e92582d/src/Tasks/IdentifyFeatures.js
*/
export const makeLayerIdentifyUrl = (
    layer,
    [lng, lat],
    [[xmin, ymin], [xmax, ymax]],
    width,
    height,
    options
) => {
    const params = {
        geometry: `${lng},${lat}`,
        geometryType: 'esriGeometryPoint',
        sr: 4326,
        f: 'json',
        layers: 'all',
        tolerance: isNumber(layer.pointQuerySettings?.tolerance)
            ? layer.pointQuerySettings.tolerance
            : 1,
        returnGeometry: false,
        imageDisplay: `${width},${height},96`,
        mapExtent: `${xmin},${ymin},${xmax},${ymax}`,
    };
    const url = new URL(layer.url);
    return `https://${url.host}${url.pathname}/identify?${querystring.stringify(
        params
    )}`;
};

export const makeLayerLegendUrl = layer => {
    const url = new URL(layer.url);
    // `substring` is used to strip off the leading ?
    const params = querystring.parse(url.search ? url.search.substring(1) : '');
    params.f = 'json';
    return `https://${url.host}${url.pathname}/legend?${querystring.stringify(
        params
    )}`;
};

export const getActiveDataLayers = (projectData, activeDataLayerIds) =>
    projectData &&
    activeDataLayerIds &&
    flatMap(projectData.layerFolders, folder =>
        folder.layerDefinitions.filter(def =>
            activeDataLayerIds.includes(def.id)
        )
    );
